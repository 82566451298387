import React from 'react';
import CalibrateButton from './CalibrateButton';
import RestartButton from './RestartButton';
import DeleteButton from './DeleteButton';
import WarnModal from '../../common/WarnModal';

//<10% dead; <20 crit; <40 half
//2-20-25 updated from -6 to 6
function getCharge(charge, current) {
  if (current && current > 6) {
    return <img className="charging" alt="battery"src={require("../../2dReact/img/battery_charging.png")}/>
  }

  let v = Math.round(charge);
  if (v < 10) {
    return <img alt="battery" src={require("../../../media/bat-dead.png")}/>
  }

  if (v < 20) {
    return <img alt="battery" src={require("../../../media/bat-crit.png")}/>
  }

  if (v < 40) {
    return <img alt="battery" src={require("../../../media/bat-half.png")}/>
  }

  return <img alt="battery" src={require("../../../media/bat-full.png")}/>
}

function getBattery(voltage, current) {
  if (current && current > -6) {
    return <img className="charging" alt="battery"src={require("../../2dReact/img/battery_charging.png")}/>
  }

  let v = parseFloat(voltage, 10);
  if (v < 3.1) {
    return <img alt="battery" src={require("../../../media/bat-dead.png")}/>
  }

  if (v < 3.15) {
    return <img alt="battery" src={require("../../../media/bat-crit.png")}/>
  }

  if (v < 3.3) {
    return <img alt="battery" src={require("../../../media/bat-half.png")}/>
  }

  return <img alt="battery" src={require("../../../media/bat-full.png")}/>
}

function getRssi(rssi) {
  let r = parseInt(rssi, 10);

  if (r < -79) {
    return <img alt="rssi" src={require("../../../media/rssi_80.png")}/>
  }

  if (r < -69) {
    return <img alt="rssi" src={require("../../../media/rssi_70_79.png")}/>
  }

  if (r < -59) {
    return <img alt="rssi" src={require("../../../media/rssi_60_69.png")}/>
  }

  if (r < -49) {
    return <img alt="rssi" src={require("../../../media/rssi_50_59.png")}/>
  }

  return <img alt="battery" src={require("../../../media/rssi_30_49.png")}/>
}

const HeaderContainer = (props) => {
  return (
    <div className="button-container">
    {
      props.charge ?
        <div className="delete">
          <div className="label">{Math.round(props.charge)}%</div>
          <div className="label2">Current:{props.current} {getCharge(props.charge, props.current)}</div>
        </div>
      :
      props.voltage &&
        <div className="delete">
          <div className="label">{props.voltage} v</div>
          <div className="label2">{getBattery(props.voltage, props.current)}</div>
        </div>
    }

    {
      props.rssi &&
      <div className="delete">
        <div className="label label3">{props.rssi} dB</div>
        <div className="label2">{getRssi(props.rssi)}</div>
      </div>
    }
    {
      props.status &&
      <div className="delete">
        <div className="label">status</div>
        <div className="label2">{props.status}</div>
      </div>
    }

    { props.handleCalibrate &&
      <CalibrateButton node={props.node} type={props.type} handleCalibrate={props.handleCalibrate}/>
    }

    { props.handleRestart &&
      <RestartButton node={props.node} handleRestart={props.handleRestart}/>
    }
    { props.handleDelete &&
      <DeleteButton node={props.node} type={props.type} name={props.name} handleDelete={props.handleDelete}/>
    }

    {
      props.delete &&
      <
        WarnModal
        button="delete"
        warn={props.message}
        action={props.delete}
      />
    }
    </div>
  )
}

export default HeaderContainer;
